
/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); */
/* @import url('<link rel="stylesheet" href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css" />'); */
*, *::after, *::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
}
html {
    font-size: 100%;
    height: 100%;
  }
  
  body {
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    line-height: 1.6875;
    color: #1A3936;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
       -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
            text-size-adjust: 100%;
    background: url(../images/topography-vectors.svg) repeat-y center bottom / 100%;
    height: 100%;
  }
  #root{
      display: flex;
      flex-direction: column;
      height: 100%;
  }
  #root > div{
      flex: 1;
  }
  img{
      max-width: 100%;
  }
  p{
    font-size: 1em;
    margin-bottom: 16px;
    color: #1A3936;
  }
  p:last-child{
      margin-bottom: 0;
  }
 :focus{
     outline: 0 !important;
     box-shadow: none !important;
 }
 ul, ol{
     list-style-type: none;
     margin: 0;
     padding: 0;
 }
 a, a:hover{
    text-decoration: none;
}

h1, h2, h3, h4{
    color: #1A3936;
    margin-bottom: 16px;
}
h1{
    font-size: 56px;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
}
h2{
    color: #1A3936;
    font-size: 32px;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
    line-height: 48px;
}
h3{
    color: #1A3936;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
}
h4{
    color: #1A3936;
    font-size: 20px;
    font-weight: 600;
}
.mt40{
    margin-top: 40px !important;
}
.mb0{
    margin-bottom: 0px !important;
}
.mb40{
    margin-bottom: 40px !important;
}
.mb60{
    margin-bottom: 60px !important;
}
.mt5{
    margin-top: 5px !important;
}
.mt10{
    margin-top: 10px !important;
}
.mt15{
    margin-top: 15px !important;
}
.mt32{
    margin-top: 32px !important;
}
.mb32{
    margin-bottom: 32px !important;
}
.mr-16{
    margin-right: 16px !important;
}
.ml-16{
    margin-left: 16px !important;
}
.mb24{
    margin-bottom: 24px !important;
}
.pt0{
    padding-top: 0 !important;
}
.pt72{
    padding-top: 72px !important;
}
.pt24{
    padding-top: 24px !important;
}
.pt32{
    padding-top: 32px !important;
}
.pb72{
    padding-bottom: 72px !important;
}
.pb80{
    padding-bottom: 80px !important;
}

section{
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
}
.faq-section {
    padding-top: 60px;
}
/* Buttons */
.btn {
    border-radius: 4px;
    padding: 10px 32px;
    font-weight: 600;
}
.btn-outline-custom {
    color: #285853;
    border: 1px solid #00634D;
}
.btn-outline-custom:hover{
    background: #00634D;
    color: #fff;
}
.btn-fill{
  background-color: #00634D;
  color: #fff;
}
.btn-fill:hover{
    background-color: #1a3936;
    color: #fff;
}

/* Form */
.inputField{
    color: #1A3936;
    
}
.inputText{
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    padding: 12px 16px;
    color: #1A3936;
}
.inputField:focus, .inputText:focus{
    outline: 0;
    border-color:#00634D;
    box-shadow: none;
    color: #1A3936;
}
.inputGroup{
    display: flex;
    align-items: center;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    padding: 4px 16px;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    line-height: 36px;
    background-color: #fff;
}
  .inputGroup:focus-within {
    border-color: #00634D;
  }
.inputField-text{
    color: #868A89;
    font-size: 16px;
    font-weight: normal;
    display: flex;
}
.inputGroup .inputField{
    border: 0;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}
.buyCardRight .inputGroup{
    max-width: 140px;
}

/* Header Menu */
.topBar{
    background: #00634D;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.headerFixed{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1001;
}
.headerFixed.sticky{
    background:  rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
}
.navbar{
    padding: 10px 0;
    height: 90px;
}
.siteLogo{
    padding: 0;
}
.siteLogo img{
    display: block;
    max-width: 100%;
}
.menuToggler{
    background: transparent;
    color: #285853;
    border: 0;
    width: 34px;
    height: 30px;
    font-size: 1.5rem;
    line-height: 1;
    position: relative;
    z-index: 999;
}
.menuToggler::before{
    content: "\f0c9";
    font-family: FontAwesome;
}
.menuToggler.active::before{
    content: "\f00d";
    /* color: #fff; */
}
.siteMenu .navbar-nav{
    align-items: center;
}
.siteMenu .navbar-nav li{
    padding-left: 24px;
    padding-right: 24px;
}
.siteMenu .navbar-nav li:last-child{
    padding-right: 0px;
}
.siteMenu .navbar-nav li .menuLink {
    position: relative;
    color: #285853;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0;
    padding: 6px 0;
    display: block;
  }
  .siteMenu .navbar-nav li .menuLink:hover{
      text-decoration: none;
  }
.siteMenu .navbar-nav li .menuLink::after{
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0px;
    width: 0px;
    height: 2px;
    background: #1A3936;
    transition: all 0.45s;
}
.siteMenu .navbar-nav li .menuLink:hover::after{
    width: 100%;
    left: 0;
}  
.siteMenu.active .navbar-nav .nav-item .btn {
    color: #fff;
    border-color: #fff;
}
.siteMenu.active{
    text-align: center;
}
.siteMenu.active .navbar-nav li:last-child {
    padding-top: 12px;
    padding-left: 0px;
    padding-right: 12px;
}
/* .siteMenu.active .navbar-nav li .menuLink, .siteMenu.active .navbar-nav li .btn{
    color: #fff;
}
.siteMenu.active .navbar-nav li .btn{
    border-color: #fff;
}
.siteMenu.active .navbar-nav li .menuLink::after {
    background: #fff;
} */
/* Banner */
.fullscreenBanner {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    overflow: hidden;
}
.fullscreenBanner::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(241,241,241,0.1) 40%);
}
.fullscreen-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.bannerContect{
    display: flex;
    justify-content: center;   
    align-items: center;
    flex-direction: column;
    height: 100%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #fff;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    z-index: 2;
}
.bannerContect h1,
.bannerContect h3{
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    font-weight: 500;
    margin-bottom: 24px;
}
.bannerContect h3{
    font-size: 24px;
    max-width: 416px;
    margin-left: auto;
    margin-right: auto;
}

/* Login Page */
.loginPage{
    width: 100%;
    height: 100vh;
    min-height: 600px;
    overflow: hidden;
}
.bgPattern{
    background: url(../images/topography-vectors.svg) no-repeat;
    background-position: 0 0;
    background-size: cover;
}
.loginInner, .innerWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 90px);
    flex-direction: column;
    padding-top: 90px;
}
.loginPage h1, .pageTitle{
    margin-bottom: 60px;
    color: #1A3936;
    text-align: center;
}
.subTitle{
    color: #868A89;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}
.loginBoxWrap{
    padding: 10px;
}
.sideBorderBox{
    position: relative;
}
/* .sideBorderBox:after, .sideBorderBox>:first-child:after{} */
.sideBorderBox:before, .sideBorderBox>:first-child:before {
    position:absolute;
    width:200px;
    height: 200px;
    border-color:#D9E303;
    border-style:solid;
    content: ' ';
}
.sideBorderBox:before {top:0;left:0;border-width: 2px 0 0 2px}
.sideBorderBox>:first-child:before {bottom:0;right:0;border-width: 0 2px 2px 0}
/* .sideBorderBox>:first-child:after {bottom:0;left:0;border-width: 0 0 1px 1px}
.sideBorderBox:after {top:0;right:0;border-width: 1px 1px 0 0} */

.loginBox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    text-align: center;
    padding: 48px;
    position: relative;
    z-index: 1;
}
.loginBtnFb, .loginBtnGplus{
    width: 100%;
    max-width: 264px;
    color: #fff;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    z-index: 2;
}
.loginBtnFb:hover, .loginBtnGplus:hover{
    color: #fff;
}
.loginBtnFb{
    background: #4267B2;
    margin-bottom: 16px;
}
.loginBtnGplus{
    background: #DC4E41
}
.loginBtnFb span, .loginBtnGplus span {
    float: left;
}
.loginBtnFb:hover{
  background-color: #5B7BD5;
}
.loginBtnGplus:hover{
  background: #E74B37;
}

/* Services */

.servicesBox{
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.servicesBox img{
    margin-bottom: 24px;
}
.servicesBox h2{
    margin-bottom: 8px;
}
.servicesBox p{
    color: #1A3936;
    font-size: 16px;
    line-height: 24px;
}

/* Home Video */
.videoBorder {
    border: 2px solid #D9E303;
    padding: 16px;
    position: relative;
}
.videoBorder::after {
    content: '';
    position: absolute;
    width: 999em;
    right: 15%;
    bottom: -60px;
    height: 80%;
    background: #00634d;
    z-index: -1;
}
.videoWrap {
    position: relative; 
    padding-bottom: 56.25%;
    z-index: 1;
    height: 0; 
    overflow: hidden; 
}
.videoWrap iframe,
.videoWrap object, 
.videoWrap embed { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
}
.homeVideoRightBlk{
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    padding-left: 60px;
}
.homeVideoRightBlk h2,
.homeVideoRightBlk p{
    margin-bottom: 16px;
}
.homeVideoRightBlk p{
    color: #1A3936
}
.socials{
    display: flex;
    align-items: center;
}
.socials li{
    margin-right: 24px;
}
.socials .customIocn, .footerSocial .customIocn{
    margin-right: 24px;    
    transition: all .2s ease-in-out;
}
.socials .customIocn img{
    width: 44px;
    height: 44px;
}
/* .socials .customIocn:hover, .footerSocial .customIocn:hover{
    transform: translateY(0);
} */
.socials .at-style-responsive .at-share-btn{
    background-color: #fff !important;
    border: 1px solid #00614e !important;
    margin-right: 24px;
}
.socials .at-style-responsive .at-share-btn:last-child{
    margin-right: 0;
}
.socials a:focus, .socials a:hover{
    transform: translateY(0) !important;
}
.socials .at-style-responsive .at-share-btn svg{
    fill: #00614e !important;
}

/* Project Piloto */
h2.projectPilotoTitle{
    font-size: 40px;
    text-align: center;
}
.projectPilotoMap{
    max-width: 1063px;
}
.projectPilotoMapWrap{
    position: relative;
    box-shadow: 0 12px 32px -8px rgba(26,26,26,0.24);
    /* min-height: 462px; */
    background-color: #EDEEED;
    display: flex;
    min-height: 100%;
}
.projectPilotoMapimg{
    flex: 1;
}
.leaderboardSection{
    /* background: rgb(249 249 249 / 95%); */
    background: #f9f9f9;
    padding: 25px 14px 25px 24px;
    min-width: 324px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
.leaderboardContent ol{
    counter-reset: item;
    padding-right: 10px;
}
.leaderboardContent ol li{
    display: block;
    border-top: 1px solid #C7C7C7;
    position: relative;
    padding: 10px 30px 10px 22px;
    color: #1A3936;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 600;
}
.leaderboardContent ol li:before {
    content: counters(item, ".")".";
    counter-increment: item;
    position: absolute;
    left: 0;
    top: 10px;
    color: #868A89;
    font-weight: normal;
    font-size: 14px;
  }
  .leaderboardContent ol li p{
      margin: 0;
      word-break: break-word;
      max-width: 90%;
  }
  .leaderboardContent ol li p {
    margin: 0;
  }
  .leaderboardContent ol li span{
    position: absolute;
    right: 0;
    top: 10px;
    font-weight: normal;
  }

/* About Us */
.aboutImgGallery{
    max-width: 818px;
    margin: 0 auto;
    padding: 15px;
    position: relative;
}
.sideBorderBox.aboutImgGallery>:first-child:before{
    display: none;
}
.aboutImgGalleryContainer{
   width: 100%; 
}
.aboutImgGallery .row{
    margin-right: -24px;
    margin-left: -24px;
    position: relative;
    z-index: 1;
}
.aboutImgGallery .row > [class^=col-]{
    max-width: max-content;
    padding-right: 24px;
    padding-left: 24px;
    position: relative;
}
.imgWrap{
    position: relative;
}
.imgWrap img{
    /* border-radius: 4px; */
    display: block;
    position: relative;
    z-index: 1;
}
.aboutImgGallery .row > [class^=col-]:last-child .imgWrap::after{
    content: "";
    height: 207px;
    width: 40px;
    background-color: #458760;
    position: absolute;
    right: -40px;
    bottom: 40px;
}
.aboutImgGallery .item h3{
    color: #1A3936;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: 24px;
    margin-bottom: 0;
}
/* Our Story */
.storyWrap{
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;    
}
.storyWrap h3{
    padding-left: 34px;
}
.storyContainer{
    border-left: 2px solid #73af69;
    padding-left: 32px;
    margin-bottom: 72px;
}
.storyContainer:last-child{
    margin-bottom: 0;
}
.pageTitleLeft{
    font-size: 40px;
    margin-bottom: 24px;
}
.storyContainer a{
    color: #285853;
    font-weight: 600;
    word-break: break-word;
}
.partners{
    background: #DBEEB9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 64px 108px;
}
.partnersImg img{
max-width: 324px;
}
.sideBorderTR:before {
    border-width:0;
}
.sideBorderBox.sideBorderTR>:first-child:before {
    top: -24px;
    right: 24px;
    border-width: 2px 2px 0 0;
    border-color: #73AF69;
    z-index: 0;
}
.partnersRight{
    position: relative;
    z-index: 1;
}
/* How We Work */
.innerContainer{
    width: 100%;
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
}
.inner-section{
    padding-top: 170px;
    padding-bottom:0px;
}
.inner-section, .whatWeWork-section{
    padding-top: 170px;
}
.AdditionalResources-section{
    padding-bottom: 32px;
}
.AdditionalResources li{
    display: flex;
    align-items: center;    
    margin-bottom: 15px;
}
.AdditionalResources li a{
    margin-left: 10px;
    font-size: 20px;
    color: #285853;
    font-weight: 700;
    
}
.faqAccordion, .buyCardWrap{
    background: #fff;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 24px;
}
.faqAccordion:last-child{
    margin-bottom: 0;
}
.faqAccordion a {
    display: block;
    position: relative;
    color: #1A3936;
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
    padding-right: 32px;
  }
.faqAccordion a:after {
    content: "";
    background: url(../images/plus.svg) no-repeat center center;
    position: absolute;
    right: 0;
    top: 8px;
    width: 16px;
    height: 16px;
}
.faqAccordion a[aria-expanded="true"]:after {
    content: "";
    background: url(../images/minus.svg) no-repeat center center;
}
.accordionBody{
    color: #868A89;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    padding-top: 10px;
}

/* Buy */
.textStyle1{
    color: #868A89;
    font-size: 20px;
    position: relative;
    padding-bottom: 3px;
}

.normalLink{
    color: #868A89;
    font-size: 20px;
    position: relative;
    padding-bottom: 3px;
}
.normalLink::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0px;
    width: 0px;
    height: 2px;
    background: #285853;
    transition: all 0.45s;
}
.normalLink:hover{
    color: #285853;
}
.normalLink:hover::after {
    width: 100%;
    left: 0;
}
.buyTop h4{
    font-weight: bold;
}
.buyCardWrap{
    padding: 24px;
    margin-bottom: 24px;
    max-width: 664px;
    margin-left: auto;
    margin-right: auto;
}
.radioCardItem{
    margin-bottom: 24px;
    max-width: 664px;
    margin-left: auto;
    margin-right: auto;
}
.buyCardWrap:last-child, .radioCardItem:last-child{
    margin-bottom: 0;
}
.radio-btn {
    position: absolute;
    visibility: hidden;
}
.radio-label {
    background: #fff;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    box-shadow: 0 4px 8px -4px rgba(26,26,26,0.2);
    /* cursor: pointer; */
    display: inline-block;
    font-weight: 600;
    padding: 20px 20px 20px 65px;
    position: relative;
    transition: .3s ease all;
    width: 100%;
}
.radio-label:before {
    background: #fff;
    border-radius: 50%;
    content: '';
    height: 24px;
    width: 24px;
    border: 1px solid #C7C7C7;
    left: 20px;
    position: absolute;
    top:25px;
    transition: .3s ease background-color;
    cursor: pointer;
}
.radio-btn:checked + .radio-label:before {
    background-color: #fff;
    border-color: #1A3936;
    cursor: default;
}
.radio-btn:checked + .radio-label {
    background: #DAF5E8;
    border-color: #1A3936;
}
.radio-label::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
}
.radio-btn:checked + .radio-label::after {
    display: block;
    height: 12px;
    width: 12px;
    left: 26px;
    position: absolute;
    top: 31px;
    background-color: #1A3936;
    transition: .3s ease background-color;
    border-radius: 50%;
    
}
.radioCardItem .title, .radioCardItem .amount, .radioCardItem .usd{
    color: #1A3936;
    font-size: 20px;
    font-weight: 600;
}
.radioCardItem .amount{
    font-weight: normal;
    margin-left: 10px;
}
.radioCardItem .usd{
    font-weight: normal;
    margin-left: 5px;
}
.radioCardItem .radio-label p{
    color: #868A89;
    font-weight: normal;
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 0px;
}
.quantityForm{
    display: none;
}
.radio-btn:checked + .radio-label .quantityForm{
    display: block;
}
.buyCardTop{
    padding-right: 15px;
    display: flex;
    align-items: center;
}
.buyCardTop h4{
    margin-bottom: 0;
    line-height: 32px;
}
.buyCardBottom{
    display: flex;
    align-items: center;
    min-width: 245px;
    justify-content: flex-end;
    margin-top: 20px;
    
    margin-left: auto;
}
.buyCardBottom .inputGroup{
    max-width: 115px;
    position: relative;
    padding-left: 32px;
}
.buyCardBottom .inputGroup.qtnTextBoxSize{
    max-width: 80px;
    padding-left: 16px;
}
.buyCardBottom .inputGroup span{
    position: absolute;
    left: 16px;
    top: 5px;
}
/* .buyCardBottom .btn{
    min-width: 120px;
} */
.amount{
    color: #1A3936;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: right;
}
.qnt{
    max-width: 72px;
    text-align: center;
}

/* Dashboard */
.dashboardCard{
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 1px 1px 8px 0 rgba(26,26,26,0.2);
    overflow: hidden;
    min-height: 100%;
}
.mapOverlay{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    cursor: pointer;
    background: rgba(26,26,26,0);
}
.dashboardCard img{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.dashboardCard .card-body{
    padding: 0;
    position: relative;
}

.cardBlank{
    padding: 50px 0;
    text-align: center;
}
.cardBlank span{
    color: #285853;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
    display: block;
}
.cardBlank .btn{
    max-width: max-content;
}
.dashboardCard .card-footer{
    background: #fff;
    border-top:0;
}
.dashboardCard .card-footer .socials {
    justify-content: center;
}
.pb50{
    padding-bottom: 50px !important;  
}
.mb30{
    margin-bottom: 30px !important;  
}
.dashboardCard .socials .at-style-responsive .at-share-btn{
    margin-bottom: 0;
}

/* Custom Modal */

.modalWindow {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1002;
    display: none;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .modalWindow.showModal {
    display: block;
    opacity: 1;
  }
  .modalWindow .modalContent {
    width: 90%;
    max-width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 2em;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.25);
  }
  .modalWindow .modalHeader {    
    margin-bottom: 30px;
  }
  .modalWindow .modalTitle {
    font-size: 24px;
    margin: 0;
    display: block;
    line-height: normal;
    font-weight: 600;
    padding-right: 30px;
  }
  
  .modal-close {
    color: #1a3936;
    line-height: 50px;
    font-size: 100%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 50px;
    text-decoration: none;
    cursor: pointer;
  }
  .modal-close:hover {
    color: #000;
  }


/* Footer */
.footer{
   background: #1A3936;
   padding: 40px 0;
}
.footerLogo{
    margin-bottom: 30px;
    display: block;
}
.footerLogo img{    
    width: 100%;
    max-width: 200px;
    display: block;
}
.footerMenu li, .footerMenu li a{
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 8px;
}
.footerMenu li:last-child, .footerMenu li a:last-child{
    margin-bottom: 0;
}
.footerMenu li a{
    display: inline-block;
    color: #FFFFFF;
    position: relative;
}
.footerMenu li a:hover{
    text-decoration: none;
}
.footerMenu li a::after{
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0px;
  width: 0px;
  height: 1px;
  background: #fff;
  transition: all 0.45s;
}
.footerMenu li a:hover::after{
  width: 100%;
  left: 0;
}  

.footerSocial li{
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 8px;
}
.footerSocial .customIocn{
    margin-right: 15px;
}
.footerSocial .customIocn img {
    height: 16px;
}
.footerSocial .at-style-responsive .at-share-btn{
    background-color: transparent !important;
}
.footerSocial li a{
    margin-right: 16px;
    display: inline-block;
    color: #fff;
}
.sitemap{
    margin: 0 auto;
    padding: 0;
    max-width: 768px;
    display: flex;
    flex-wrap: wrap;
}
.sitemap li{
    display: block;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    min-height: 1px;
}
.sitemap li a{    
    font-size: 20px;
    color: #285853;
    font-weight: 700;
}
.copyright{
    color: rgba(255,255,255,0.72);
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
}
.progressPreloaderWrap{
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
}
.progressPreloaderWrap h2{
    font-size: 40px;
}
.progressPreloader{
    position: relative;
}
.progressOverlap{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: url(../images/progress-original.png) no-repeat center bottom / 100%;
    z-index: 1;
}
.progressImg{
     position: relative;
     z-index: 2;
     display: inline-block;
}
.progressStepWrap{
    position: absolute;
    right: 0;    
    top: 0;
    width: 100%;
    max-width: 480px;
    height: 100%;
}
.progressStep{
    display: flex;
    align-items: flex-end;
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 480px;
}
.progressStep .bar{
    background: #D2D3D3;
    width: 100%;
    height: 2px;
    margin-right: 10px;    
    display: block;    
}
.progressStep span {
    color: #868A89;
    font-size: 20px;
    line-height: normal;
    display: block;
    min-width: 100px;
    background: #fff;
}
.progressStep.top{
    bottom: calc(100% - 2px);
}
.progressStep.bottom{
    bottom: 2px;
}
.progressStep.active {
    align-items: flex-end;
}
.progressStep.active span {
    color: #1A3936;
    font-size: 24px;
    font-weight: bold;
    z-index: 3;
}
.progressStep.active .bar{
    background: #8DC644;
    z-index: 1;
}
.progressStep.active span span{
    font-size: 16px;
    font-weight: 600;
}

/* Checkout */
.checkoutWrap{
    width: 100%;
    max-width: 664px;
    margin: 0 auto;
    counter-reset: stepCounter;
}
.checkoutBlock{
    background: #fff;
    border: 1px solid #C7C7C7;    
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 42px;    
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px -4px rgba(26,26,26,0.2);
    position: relative;
    
}
.checkoutBlock:before, .checkoutBlock.completed::before{
    counter-increment: stepCounter; 
    content: counter(stepCounter); 
    position: absolute;
    left: 24px;
    top: 24px;
    color: #000;
    font-weight: normal;
    font-size: 24px;
    height: 32px;
    width: 32px;
    border: 1px solid #50887D;
    border-radius: 50px;
    text-align: center;
    line-height: 28px;
    font-weight: bold;
  }
.checkoutBlock.completed::before{
    opacity: 0;
    visibility: hidden;
}
.checkoutBlock.completed::after{
    content: "";
    position: absolute;
    left: 24px;
    top: 24px;
    color: #000;
    font-weight: normal;
    font-size: 24px;
    height: 32px;
    width: 32px;
    border: 1px solid #E0F8D0;
    border-radius: 50px;
    text-align: center;
    line-height: 28px;
    font-weight: bold;
    background: #E0F8D0 url(../images/tick.svg) no-repeat center center;
}
.checkoutBlockTitle h2{
    color: #1A3936;
    font-size: 24px;
    font-weight: bold;
    padding-left: 46px;
    margin-bottom: 0;
    line-height: normal;
}
.checkoutContent{
    margin-top: 24px;
    margin-left: 46px;
    display: none;
}
.checkoutBlock.active .checkoutContent{
    display: block;
}
.checkoutBlock.completed .checkoutContent{
    display: none;
}
.paypal{
    background: #ffc439;
    border-radius: 4px;
    display: inline-block;
    padding: 12px;
    width: 100%;
    max-width: 264px;
    text-align: center;
}
.reviewCard{
    display: flex;
    align-items: center; 
}
.reviewCard h4{
    word-break: break-word;
    padding-right: 10px;
    margin-bottom: 0;
}
.reviewCard .right{
    margin-left: auto;
    display: flex;
    align-items: center;
}
.cartForm{
    display: flex;
    align-items: center;
    min-width: 110px;    
}
.cartForm .inputText{
    max-width: 60px;
    padding: 5px;
    text-align: center;
    margin-right: 16px;
}
.reviewCard .right .cartForm span{
    margin-right: 16px;
    font-size: 20px;
    cursor: pointer;
} 
.reviewCard .right .amount{
    margin-left: 8px;
}
.cursorPointer{
    cursor: pointer;
}
.reviewCard .right .cross{
    margin-left: 24px;
    cursor: pointer;
}
.reviewTotal{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.reviewTotal h4{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
}
.reviewTotal h4:last-child{
    margin-right: 24px;
}


.placeholderHideFocus:focus::-webkit-input-placeholder  {color:transparent;}
.placeholderHideFocus:focus::-moz-placeholder   {color:transparent;}
.placeholderHideFocus:-moz-placeholder   {color:transparent;}

.fontRaleway{
    font-family: 'Raleway', sans-serif !important;
}
.fontQuicksand{
    font-family: 'Quicksand', sans-serif !important;
}

.mapModal .modalContent{
    max-width:860px;
    padding: 0;
}
.mapModal .modalBody{
    display: flex;
    min-height: 100%;
}
.modalLeft{
    position: relative;
    width: 100%;
}
.mapRight{
    display: flex;
    min-height: 100%;
    width: 100%;
    max-width: 300px;
    background-color: #fff;
    flex-direction: column;
    padding: 40px;
  }
  .mapRight h6{
    color: #868A89;
  }
  .mapRight p{
    color: #1A3936;
    font-size: 20px;
  }
.mapRightTopContent{
    flex: 1;
    margin-bottom: 24px;
} 
.mapModal .modalContent .arrow-left, .mapModal .modalContent .arrow-right{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;    
    cursor: pointer;
  }
  .mapModal .modalContent .arrow-left{
    left: -50px;
    background: url(../images/arrow-left.svg) no-repeat left center;
  }
  .mapModal .modalContent .arrow-right {
    
    right: -50px;
    background: url(../images/arrow-right.svg) no-repeat right center;
  }
  .mapModal .close {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: -30px;
    z-index: 1;
    cursor: pointer;
    opacity: 1;
    background: url(../images/cross.svg) no-repeat center center;
}

.pageLoader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 2;
}
.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 5px solid #8dc644;
    border-radius: 50%;
    border-top: 5px solid #00634D;
    width: 50px;
    height: 50px;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
    transform: translate(-50%, -50%);
}
 /* Safari */
 @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.pageError{
    color: #f00;
}
.leaflet-container{
    height: 400px;
}

.bullets{
    list-style-type: disc !important;
    margin: 0 0 0 40px;
}
.bullets li{
    margin-bottom: 16px;
}
.bullets li:last-child{
    margin-bottom: 0;
}


@media (min-width: 576px){
    .footerCol >[class^=col-]{
        max-width: max-content;
    }
    .container {
        max-width: 90%;
    }
    .sitemap li{
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width: 768px){
    .container{
        max-width: 90%;
    }
}
@media (min-width: 860px){
    .siteLogo{
        max-width: 100%;
     }    
}
@media (min-width: 992px){
    .leaderboardContent ol {
        overflow: auto;
        max-height: 305px;
    }
    .leaderboardContent ol li p {
        max-width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
}
@media (min-width: 992px){
    .menuToggler{
        display: none;
    }     
}
@media (min-width: 1340px){
    .container{
        max-width: 1280px;
    }
    .leaderboardContent ol {
        overflow: auto;
        max-height: 335px;
    }
}
@media (max-width: 1024px){
    .leaderboardContent ol {
        max-height: 228px;
        overflow: auto;
    }
}
@media (max-width: 991px){
    .siteMenu{
        display: none;
        top: 0;
        right: 0;
        position: fixed;
        width: 100%;
        max-width: 100%;
        height: 350px;
        background: linear-gradient(180deg, #FFFFFF 40%, rgba(241,241,241,0.9) 100%);
        padding: 120px 15px 15px 15px;
        box-shadow: 0 0 5px 0px rgb(6 83 66 / 90%);
        z-index: 100;        
    }
    .siteLogo{
        z-index: 101;
    }
    .siteMenu.active{
        display: block;
    }
    .siteMenu.active .navbar-nav .nav-item{
        margin: 0;
    }
    .siteMenu.active .navbar-nav .nav-item .btn{
        margin-top: 12px;
    }
    .siteMenu .navbar-nav li {
        width: 100%;
        text-align: right;
    }
    .siteMenu .navbar-nav li .menuLink{
        padding: 12px 0;
    }
    .mapLeft{
        padding: 0;
    }
    .mapRight{
        position: relative;
    }
    .projectPilotoMapWrap{
        flex-direction: column;
    }
    .leaderboardContent ol {
        max-height: 400px;
        overflow: auto;
    }
    .topBar{
        position: relative;
        z-index: 1000;
        width: 100%;
    }
    .mapModal .modalContent .arrow-left, .mapModal .modalContent .arrow-right {    
        background-color: rgba(0, 0, 0, 0.2);
        background-position: center;
        background-size: 24px;
    }
    .mapModal .modalContent .arrow-left{
        left: 0;
    }
    .mapModal .modalContent .arrow-right {    
        right: 0px;
    }
}
@media (max-width: 900px){
    .partners{
        padding: 64px 50px;
    }
}
@media (max-width: 767px){
    .navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid, .navbar-expand-lg>.container-lg, .navbar-expand-lg>.container-md, .navbar-expand-lg>.container-sm, .navbar-expand-lg>.container-xl {
        padding-right: 15px;
        padding-left: 15px;
    }
    section{
        padding: 50px 0;
    }
    .faq-section {
        padding-top: 20px;
    }
    .inner-section, .whatWeWork-section {
        padding-top: 140px;
    }
    .homeVideoRightBlk {
        padding-top: 80px;
        padding-left: 0;
    }
    h1 {
        font-size: 48px;
    }
    .servicesBox{
        margin-bottom: 30px;
    }
    .sec-services .col-md-4:last-child .servicesBox{
        margin-bottom: 0px;
    }
    .projectPilotoMapWrap{
        flex-direction: column;
    }
    .projectPilotoMapRight{
        min-width: initial;
    }
    .aboutImgGallery .row > [class^=col-]{
        margin-bottom: 48px;
    }
    .aboutImgGallery .row > [class^=col-]:last-child{
        margin-bottom: 0;
    }
    .storyContainer {
        margin-left: 0;
    }
    .partners{
        flex-direction: column;
        text-align: center;
    }
    .partnersRight{
        margin-top: 40px;
    }
    .mapModal .modalBody{
        flex-direction: column;
    }
    .videoBorder::after {
        bottom: -40px;
    }
}
@media (max-width: 639px){
    .progressImg {
        max-width: 300px;
    }
    .progressStep {
        flex-direction: column-reverse;
    }
    .progressStep span {
        font-size: 16px;
        text-align: right;
        margin-bottom: 5px;
        min-width: 0;
    }
    .progressStep span.bar {
        margin: 0;
    }
    .progressStep.active span {
        font-size: 18px;
    }
    .progressStep.active span span{
        font-size: 14px;
    }
}
@media (max-width: 575px){
    .footerCol >[class^=col-]{
        margin-bottom: 30px;
    }
    .footerCol >[class^=col-]:last-child{
        margin-bottom: 0px;  
    }
    .loginBox{
        padding: 48px 24px;
    }
    .aboutImgGalRow {
        flex-direction: column;
    }
    .loginPage{
        height: 100%;
    }
    .buyCardWrap {
        padding: 15px;
    }
    .buyCardBody{
        flex-direction: column;
    }
    .buyCardLeft{
        margin-bottom: 16px;
    }
}
@media (max-width: 479px) {
    .checkoutContent {
        margin-left: 0;
    }
    .reviewCard h4,
    .reviewCard .right .amount {
        font-size: 16px;
    }
    .reviewTotal h4:last-child {
        margin-right: 20px;
    }
}
@media (max-width: 425px) {
    .aboutImgGallery .row > [class^=col-]:last-child .imgWrap::after {
        right: -30px
    }
}
@media (max-width: 420px) {
    .siteLogo {
        max-width: 75%;
        margin-right: 0;
    }
    .partnersImg img {
        max-width: 230px;
    }
    .partners {
        padding: 64px 25px;
    }
    .buyCardBottom .btn {
        min-width: 105px;
        padding: 10px 16px;
    }
}
